import { Link, LinkProps, useMultiStyleConfig } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { NextLink } from "src/components/NextLink";
import { useTranslatedString } from "src/i18n/i18n";
import { ArrowLeft } from "src/icons";
import { isPathHome } from "src/lib/utils";

type HeroBackLinkProps = LinkProps & { to: string; label?: string };

export const HeroBackLink: React.FC<HeroBackLinkProps> = ({
	to,
	label,
	...props
}) => {
	const styles = useMultiStyleConfig("HeroBackLink");
	const t = useTranslatedString();
	const { pathname } = useRouter();

	const isCurrent =
		to === pathname || (isPathHome(to) && isPathHome(pathname));

	return isCurrent ? null : (
		<NextLink href={to} passHref>
			<Link
				data-testid="hero-backlink"
				variant="text"
				sx={styles.link}
				{...props}
			>
				<ArrowLeft sx={styles.icon} />
				{label ??
					(to === "/"
						? t("heroBackLink.to.home")
						: t("heroBackLink.to.default"))}
			</Link>
		</NextLink>
	);
};

// 🔬 TBD: e2e?
