import { chakra, Container, useMultiStyleConfig } from "@chakra-ui/react";
import { useMemo } from "react";
import {
	InlineLinkButtonFragment,
	InlineModalButtonFragment,
} from "src/__generated__/cms-schema.codegen";
import { BackgroundShape } from "src/components/BackgroundShape/BackgroundShape";
import { CMSButton } from "src/components/CMSButton/CMSButton";
import {
	DatoAsset,
	DatoAssetData,
	DatoImageProps,
} from "src/components/Dato/DatoAsset";
import { Hx } from "src/components/Heading/Heading";
import { HeroBackLink } from "src/components/HeroBackLink/HeroBackLink";
import { FormattedPriceDescription } from "src/components/Product/Product";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import { getAnalyticsId } from "src/lib/utils.esm";

const PriceTag: React.FC<Pick<HeroProps, "startingPrice" | "priceTagText">> = ({
	priceTagText,
	startingPrice,
}) => {
	const styles = useMultiStyleConfig("Hero");

	if (!startingPrice) {
		return null;
	}

	return (
		<chakra.div __css={styles.priceTag}>
			<FormattedPriceDescription
				sx={styles.priceDescription}
				price={startingPrice}
				priceDescription={priceTagText}
			/>
		</chakra.div>
	);
};

const BackgroundShapeRenderer: React.FC<{ heroVariant: string }> = ({
	heroVariant,
}) => {
	// The fade variant is an image that horizontally stretches and fades.
	// It obscures the background shape, so no need to include it.
	if (
		heroVariant === "fade" ||
		heroVariant === "slide" ||
		heroVariant === "price"
	) {
		return null;
	}

	return <BackgroundShape offset={65} variant="withGradient" />;
};

export type HeroProps = {
	id: string;
	background: DatoAssetData;
	title: string;
	subline?: string;
	variant: string;
	cta?: Array<InlineLinkButtonFragment | InlineModalButtonFragment>;
	text?: StructuredTextData;
	priceTagText?: string;
	startingPrice?: number;
};

const Hero: React.FC<HeroProps> = ({
	id,
	background,
	title,
	subline,
	cta,
	variant,
	text,
	priceTagText,
	startingPrice,
}) => {
	const styles = useMultiStyleConfig("Hero", {
		variant,
	});

	const imageProps: DatoImageProps = useMemo(() => {
		switch (variant) {
			case "fade":
				return {
					layout: "fill",
					objectFit: "cover",
					objectPosition: "48% 41%",
				};
			case "slide":
			case "price":
				return {
					layout: "responsive",
					objectFit: "cover",
					height: "100%",
				};

			default:
				return {};
		}
	}, [variant]);

	return (
		<chakra.div __css={styles.wrapper}>
			<BackgroundShapeRenderer heroVariant={variant} />

			<chakra.div __css={styles.media}>
				<DatoAsset
					data={background}
					usePlaceholder={false}
					datoImageProps={imageProps}
					priority={true}
				/>

				<PriceTag
					priceTagText={priceTagText}
					startingPrice={startingPrice}
				/>
			</chakra.div>
			<Container maxW="container.xl" sx={styles.main}>
				<chakra.div __css={styles.inner}>
					<chakra.div __css={styles.content}>
						<HeroBackLink
							sx={styles.backLink}
							id={getAnalyticsId(id, "backlink")}
							to="/"
						/>

						<Hx as="h1" sx={styles.title}>
							{title}
						</Hx>
						{subline && (
							<chakra.p __css={styles.subline}>
								{subline}
							</chakra.p>
						)}

						{text && (
							<chakra.div __css={styles.text}>
								<StructuredText data={text} />
							</chakra.div>
						)}

						{cta?.[0] && (
							<chakra.div __css={styles.actionsDesktop}>
								<CMSButton
									{...cta[0]}
									analyticsId={getAnalyticsId(
										cta[0].analyticsId,
										"desktop",
									)}
								/>
							</chakra.div>
						)}
					</chakra.div>
				</chakra.div>
				{cta?.[0] && (
					<chakra.div __css={styles.actionsMobile}>
						<CMSButton
							{...cta[0]}
							analyticsId={getAnalyticsId(
								cta[0].analyticsId,
								"mobile",
							)}
						/>
					</chakra.div>
				)}
			</Container>
		</chakra.div>
	);
};

export { Hero };

// 🔬 TBD: Please evaluate
